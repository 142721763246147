import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { Input, Button, Select, notification, Spin, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { fetchCalculateAndUpdate, fetchOrderAggregate, } from '../../redux/orderSlice';

import {
  changeCartItem,
  calculateItemPrice,
  loncaFormatDate,
  loncaRound,
  renderCalculatedVendorCogs,
  calculateVendorCogs,
  renderLoncaVendorCurrency
} from '../../helpers';

import usePrompt from "../../hooks/usePrompt";

import {
  SUPPLIER_VALID_ORDER_PRODUCTS_ITEM_STATUSES,
  SUPPLIER_VALID_ORDER_PRODUCTS_ORDER_STATUSES,
  SUPPLIER_DISABLE_EDIT_ORDER_STATUSES,
  SUPPLIER_VALID_ORDER_PRODUCTS_EDIT_ITEM_STATUSES,
  SUPPLIER_VALID_LATE_PRODUCTS_ITEM_STATUSES
} from "../../constants";

import './OrderProducts.css';

function OrderProducts({ type }) {
  const { t } = useTranslation('translation');
  const { Option } = Select;

  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { selectedLanguage, user } = useSelector((state) => state.user);
  const { vendor } = useSelector((state) => state.vendor);

  const [orders, setOrders] = useState([]);
  const [initialOrders, setInitialOrders] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusfFilter] = useState('');
  const [sort, setSort] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
  const [stockOutWarning, setStockOutWarning] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const csvInstance = useRef();

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);

  useEffect(() => {
    if (vendor?._id && user) {
      setSelectedVendors([
        vendor?.name,
        ...(user?.additional_vendors?.map(v => (v?.name)) || [])
      ]);
      setAllVendors([
        vendor?._id,
        ...(user?.additional_vendors?.map(v => (v?._id)) || [])
      ]);
    }
  }, [vendor, user])

  useEffect(() => {   
    setStatusfFilter('')
    setSearchText('')
    setSort(-1)
  }, [type])

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    if (vendor?._id && type === "order_products") {
      fetchOrderAggregate({
        filter: [
          searchText,
          selectedVendors,
          statusFilter,
          sort,
          SUPPLIER_VALID_ORDER_PRODUCTS_ORDER_STATUSES,
          SUPPLIER_VALID_ORDER_PRODUCTS_ITEM_STATUSES
        ],
        mode: "order_products",
        cancelToken: source.token
      })
        .then((res) => {
          const cartItemMap = res.data?.map((item) => {
            item.coupon = allVendors?.includes(item?.coupon?.vendor) ? item?.coupon : null
            return item;
          });
          setOrders(cartItemMap);
          setInitialOrders(cartItemMap);
          setLoading(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }

    if (type === "late_products") {
      fetchOrderAggregate({
        filter: [
          searchText,
          selectedVendors,
          statusFilter,
          sort
        ],
        mode: "late_products",
        cancelToken: source.token
      })
        .then((res) => {
          const cartItemMap = res.data?.map((item) => {
            item.coupon = allVendors?.includes(item?.coupon?.vendor) ? item?.coupon : null
            return item;
          });
          setOrders(cartItemMap);
          setInitialOrders(cartItemMap);
          setLoading(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }

    return () => {
      source.cancel('Operation canceled by the user.');
    }

  }, [type, sort, refresh, searchText, vendor, statusFilter, selectedVendors, allVendors]);

  const handleRefresh = () => {
    setOrders([]);
    setChangedItems([]);
    setIsDirty(false);
    setRefresh(r => !r);
  }

  const handleChangeCartItem = ({ item, field, value, initialItem }) => {
    let newOrders = orders.slice();
    // find index of original item
    let orderIndex = newOrders.findIndex((order) => order?.cart_item?._id === item._id);
    // apply updates and set orders
    changeCartItem({
      item: newOrders[orderIndex],
      setItem: (newOrder) => { newOrders[orderIndex] = newOrder },
      field,
      value,
      currentItem: item,
      series: seriesMain,
      isOrderStatus: true,
      initialItem,
      owner : user?.full_name
    }, setStockOutWarning);
    setOrders(newOrders);

    // find changed item for same orderId
    let relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === newOrders[orderIndex]?._id?.toString());
    // find item of changed item for same orderId and same cart_item id
    let relatedCartItem = null;
    if (relatedOrder) {
      relatedCartItem = relatedOrder?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === newOrders[orderIndex]?.cart_item?._id?.toString())
    }

    const updatedItem = newOrders?.find(o => o?.cart_item?._id?.toString() === item?._id?.toString())?.cart_item;

    const updateObj = {
      _id: newOrders[orderIndex]?.cart_item?._id,
      [field]: value
    }

    if (field === "order_status") {
      updateObj.status_log = updatedItem?.status_log;
    }

    if (relatedOrder) {
      if (relatedCartItem) {
        // if related cart item exists, find it and replace with the new version
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems?.filter(cart_item => cart_item?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
              {
                item: {
                  ...relatedCartItem?.item,
                  ...updateObj
                },
                type: "update"
              }
            ]
          }
        ])
      } else {
        // if relatedOrder exists, add updated item to its cartItems
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems,
              {
                item: updateObj,
                type: "update"
              }
            ]
          }
        ])
      }
    } else {
      // if relatedOrder doesn't exists, add a new changed item with deleted item
      setChangedItems(items => [
        ...items, 
        { 
          orderId: newOrders[orderIndex]?._id, 
          cartItems: [{ 
            item: updateObj,
            type: "update" 
          }] 
        }
      ]);
    }

    setIsDirty(true);
  }

  const handleSave = ({ forceUpdate = false, conflictCheckOnly = true }) => {

    setSaveLoading(true);

    const beforeUpdateOrders = [];

    for (const order of changedItems) {
      let beforeUpdateOrder = { orderId: order?.orderId, cartItems: [] };

      for (const item of order?.cartItems) {

        let orderItem = initialOrders?.find(o => o?._id?.toString() === order?.orderId?.toString() && o?.cart_item?._id?.toString() === item?.item?._id?.toString());
        let beforeUpdateItem = { item: { _id: item?.item?._id }, type: item?.type };

        for (const key of Object.keys(item?.item)) {
          beforeUpdateItem.item[key] = orderItem?.cart_item?.[key];
        }

        beforeUpdateOrder.cartItems.push(beforeUpdateItem);
      }

      beforeUpdateOrders.push(beforeUpdateOrder);
    }

    fetchCalculateAndUpdate(changedItems, beforeUpdateOrders, forceUpdate, conflictCheckOnly)
      .then((res) => {
        setSaveLoading(false);

        if (res?.conflict) {

          const conflictedOrders = orders?.filter(order => 
            res?.nonConflictedCartItems.some(cartItem => order?.cart_item?._id === cartItem?.item?._id)
          );

          conflictedOrders?.map(order => {

            const code = order?.cart_item?.supplier_stock_code;

            const message = t('fields.order_products.overwrite_notif') + code || "";
            notification['success']({
              message: message,
            });
            
          })
          
          // setShowOverwriteWarning(true);
          handleSave({ forceUpdate: false, conflictCheckOnly: false });
          return;
        }

        setIsDirty(false);
        handleRefresh();
        notification['success']({
          message: 'Changes saved successfully',
        });

      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err)
        notification['error']({
          message: 'Changes couldn\'t saved successfully',
        });
      });
  }

  const handleExport = async () => {

    setExportLoading(true);


    const headers = [
      { label: "Sipariş Numarası", key: "unique_order_id" },
      { label: "Tedarikçi Stok Kodu", key: "product_name" },
      { label: "Ürün Paket Barkodu", key: "product_misfire_barcode" },
      { label: "Toptancı", key: "vendor" },
      { label: "Toptancı Ürün Linki", key: "supplier_link" },
      { label: "Sipariş Tarihi", key: "order_date" },
      { label: "Sipariş Durumu", key: "order_status" },
      { label: "Ürün Sayısı", key: "item_count" },
      { label: "Paket Sayısı", key: "quantity" },
      { label: "Paket", key: "product_series" },
      { label: "Adet Fiyatı (KDV Hariç)", key: "product_unit_price_without_vat" },
      { label: "Adet Fiyatı (KDV Dahil)", key: "product_unit_price_with_vat" },
      { label: "Toplam Fiyat (KDV Dahil)", key: "product_total_price" },
      { label: "Kur Bilgisi", key: "order_currency_rate" },
      { label: "Kullanılan Kupon", key: "used_coupon" },
      { label: "İndirim Miktarı", key: "discount" },
    ];

    setExportHeaders(headers);
    const data = orders?.map(item => {
      const cogs = calculateVendorCogs({
        payment_at: item?.payment_at, 
        cogs: item?.cart_item?.cogs, 
        rate_usd_tr_buying: item?.rate_usd_tr_buying, 
        rate_usd_buying: item?.rate_usd_buying, 
        rate_usd_selling: item?.rate_usd_selling, 
      })
      return {
        unique_order_id: item?.unique_order_id,
        product_name: item?.cart_item?.supplier_stock_code || (selectedLanguage?.code === "tr" ? item?.cart_item?.product_name_tr : item?.cart_item?.product_name),
        vendor: item?.cart_item?.vendor?.name,
        supplier_link: item?.cart_item?.supplier_link || "",
        order_date: loncaFormatDate(item?.payment_at),
        order_status: item?.cart_item?.order_status,
        quantity: item?.cart_item?.quantity,
        item_count: item?.cart_item?.item_count,
        product_series: item?.cart_item?.series,
        product_misfire_barcode: item?.cart_item?.vendor_product_id?.[item?.cart_item?.series],
        product_unit_price_without_vat: renderCalculatedVendorCogs({
                                            payment_at: item?.payment_at, 
                                            cogs: item?.cart_item?.cogs, 
                                            rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                                            rate_usd_buying: item?.rate_usd_buying, 
                                            rate_usd_selling: item?.rate_usd_selling, 
                                          }),
        product_unit_price_with_vat: `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${loncaRound(calculateItemPrice({
          item: {
            ...item,
            product: {
              product_type: { vat: item?.cart_item?.vat }
            },
            cogs,
          }, order: true
        })
        )}`,
        product_total_price: `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${loncaRound(calculateItemPrice({
          item: {
            ...item,
            product: {
              product_type: { vat: item?.cart_item?.vat }
            },
            cogs,
          },
          order: true
        }
        ) * item?.cart_item?.item_count * item?.cart_item?.quantity)}`,
        order_currency_rate: item?.rate_usd_tr_selling?.toString() || "",
        used_coupon: item?.coupon?.code || "",
        discount: item?.coupon && item?.coupon?.discount_type === "Percentage" ?
          `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${Math.round((calculateItemPrice({
            item: {
              ...item,
              product: {
                product_type: { vat: item?.cart_item?.vat }
              },
              cogs,
            },
            order: true
          }
          ) * item?.cart_item?.item_count * item?.cart_item?.quantity) * (item?.coupon?.discount_amount) * 10) / 10}` : "",
      }

    });

    setExportData(data);

    setExportLoading(false);

  }

  useEffect(() => {
    if (exportData?.length && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData(false);
      });
    }
  }, [exportData]);

  const isReceivedAndNotReceivedItem = (item) => {
    return item?.status === "Received" && item?.cart_item?.order_status !== "Received"
  } 

  return (<div className='order-products'>
    {<Modal 
      title={t('fields.order_products.stockout_warning')}
      onCancel={() => {
        setStockOutWarning(false);
      }}
      visible={stockOutWarning}
      footer={[
        <Button
          key="ok" 
          type="primary"
          onClick={() => {
            setStockOutWarning(false);
          }}
        >
          {t('fields.order_products.OK')}
        </Button>
      ]}
    >
      {t('fields.order_products.stockout_warning_message')}
    </Modal>}

    <Modal 
      title={t('fields.order_products.overwrite_warning_title')}M
      visible={showOverwriteWarning}
      onCancel={() => setShowOverwriteWarning(false)}
      footer={[
        <Button
          key="ok" 
          type="primary"
          onClick={() => { 
            setShowOverwriteWarning(false);
            handleSave({ forceUpdate: true, conflictCheckOnly: false });
          }}
        >
          {t('fields.order_products.save_and_overwrite')}
        </Button>,
        <Button 
          key="cancel" 
          type="secondary"
          onClick={() => { 
            setShowOverwriteWarning(false);
            handleSave({ forceUpdate: false, conflictCheckOnly: false });
          }}
        >
          {t('fields.order_products.save_not_overwrite')}
        </Button>
      ]}
    >
      <p>{t('fields.order_products.overwrite_warning')}</p>
    </Modal>

    <div className='top-bar'>
      <h2>{type === "late_products" ? t(`home.Late Products`) : t(`home.Order Products`)}</h2>
      <div className='top-btns'>
        { type === "order_products" && 
          <Button 
            className='save-btn' 
            type='primary' 
            loading={saveLoading} 
            onClick={() => handleSave({ forceUpdate: false, conflictCheckOnly: true })}
          >
            {t(`buttons.Save`)}
          </Button>
        }
        
        { type === "order_products" && 
          <Button 
            className='discard-btn' 
            type='primary' 
            onClick={() => {
              if (window.confirm('Do you want do discard all changes?')) handleRefresh();
            }}
          >
            {t(`buttons.Discard Changes`)}
          </Button>
        }

        <Button className='discard-btn' type='secondary' loading={exportLoading} onClick={handleExport}>{t(`buttons.Export CSV`)}</Button>

        <CSVLink
          data={exportData || []}
          headers={exportHeaders || []}
          filename={`lonca_siparis_urunleri_${loncaFormatDate(new Date())}.csv`}
          ref={csvInstance}
        />
      </div>
    </div>
    <div className='form-label-bar-order-products'>
      <div className='search-bar-empty'></div>
      {user?.additional_vendors?.length > 0 &&
        <div className='form-bar-select'>
          {t(`home.Vendor`)}
        </div>
      }
      <div className='form-bar-select'>
        {t(`home.status`)}
      </div>
      <div className='form-bar-select'>
        {t(`sort.Sort`)}
      </div>

    </div>
    <div className='form-bar'>
      <Input className='search-bar'  value={searchText} placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />

      {user?.additional_vendors?.length > 0 &&
        <Select
          onChange={(e) => {
            if (e === "all") {
              setSelectedVendors([
                vendor?.name,
                ...(user?.additional_vendors?.map(v => (v?.name)) || [])
              ]);
              return
            }
            setSelectedVendors([e]);
          }}
          className='form-bar-select'
          showSearch
          optionFilterProp="children"
          defaultValue={'all'}
          filterOption={(input, option) =>
            option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
          }
        // style={{ maxWidth: "120px" }}
        >
          <Option value="all">{t(`home.All Vendors`)}</Option>
          <Option value={vendor?.name}>{vendor?.name}</Option>
          {user?.additional_vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
            <Option key={'vendor-select' + index} value={vendor.name}>
              {vendor.name}
            </Option>
          ))}
        </Select>}
      <Select className='form-bar-select' placeholder='Status' defaultValue={''} value={statusFilter} onChange={setStatusfFilter}>
        {
          [
            'Any', 
            ...(
              type === "late_products" 
                ? SUPPLIER_VALID_LATE_PRODUCTS_ITEM_STATUSES 
                : SUPPLIER_VALID_ORDER_PRODUCTS_ITEM_STATUSES
            )
          ].map((status) => {
            return <Option key={status} value={status === "Any" ? "" : status}>{t(`status.${status}`)}</Option>
          })
        }
      </Select>


      <Select className='form-bar-select' placeholder='Sort' defaultValue={-1}  value={sort} onChange={setSort}>
        <Option key='new-to-old' value={-1}>{t(`sort.New to Old`)}</Option>
        <Option key='old-to-new' value={1}>{t(`sort.Old to New`)}</Option>
      </Select>

    </div>

    <div className='product-table'>
      {loading && <Spin tip={t(`placeholders.Loading Products...`)} />}
      {orders?.map((item, index) => {

        const unitPrice = calculateItemPrice({
          item: {
            ...item,
            product: {
              product_type: { vat: item?.cart_item?.vat }
            },
            cogs: calculateVendorCogs({
              payment_at: item?.payment_at, 
              cogs: item?.cart_item?.cogs, 
              rate_usd_tr_buying: item?.rate_usd_tr_buying, 
              rate_usd_buying: item?.rate_usd_buying, 
              rate_usd_selling: item?.rate_usd_selling, 
            })
          }, order: true
        });

        const totalPrice = loncaRound(unitPrice * item?.cart_item?.item_count * item?.cart_item?.quantity);
        const discountAmount = Math.round(totalPrice * (item?.coupon?.discount_amount) * 10) / 10;

        const itemDate = item?.cart_item?.order_addition_date ? item?.cart_item?.order_addition_date : item?.payment_at;

        return (
          <div className={`product-container ${changedItems?.find(changedItem => changedItem?.orderId?.toString() === item?._id && changedItem?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === item?.cart_item?._id?.toString())) && 'changed'}`} key={item._id + index}>
            <div className='product-image-container'>
              <img className='product-image' src={item?.cart_item?.product_image} alt="product"></img>
            </div>
            <div className='product-info'>

            <div className="same-row-container">
                <span>{t(`fields.product.unique_id`)}
                  <input className={`ant-input`} value={item?.unique_order_id} disabled />
                </span>
                {item?.external_order_ids?.[item?.cart_item?.vendor_name?.toLowerCase()]?.[0] && <span>{t(`fields.product.supplier_unique_id`)}
                  <input className={`ant-input`} value={item?.external_order_ids?.[item?.cart_item?.vendor_name?.toLowerCase()]?.[0]} disabled />
                </span>}
                <span>{t(`fields.order_products.Payment At`)}
                  <input className={`ant-input`} placeholder='Order Date' value={loncaFormatDate(itemDate)} disabled />
                </span>
                <span>
                <TextArea 
                  rows={2} 
                  onChange={(e) => {
                    handleChangeCartItem({ item: item?.cart_item, field: 'notes', value: e.target.value })
                  }} 
                  value={item?.cart_item?.notes} 
                  disabled={type === "late_products"}
                />
                </span>
                </div>
                <div>
                <span className='product-info-label'>{t(`fields.product.Status`)}</span>
                <Select 
                  style={{width: '100%'}} 
                  placeholder='Order Status' 
                  value={item?.cart_item?.order_status} 
                  onChange={
                    (e) => handleChangeCartItem({ item: item?.cart_item, field: 'order_status', value: e, initialItem: initialOrders?.filter(initialItem => initialItem?.cart_item?._id === item?.cart_item?._id)[0]?.cart_item })
                  }
                  disabled={type === "late_products" || SUPPLIER_DISABLE_EDIT_ORDER_STATUSES.includes(item.status) && item?.cart_item?.order_status !== "Received" || isReceivedAndNotReceivedItem(item)}
                >
                  {
                    SUPPLIER_VALID_ORDER_PRODUCTS_EDIT_ITEM_STATUSES.map((status) => {
                      return <Option key={status} value={status}>{t(`status.${status}`)}</Option>
                    })
                  }
                </Select>
                </div>
                <span>{t(`fields.product.Lonca Name`)}
                  <input className={`ant-input`} placeholder='COGS' value={selectedLanguage?.code === "tr" ? item?.cart_item?.product_name_tr : item?.cart_item?.product_name} disabled />
                </span>
                
                <span>{t(`fields.product.Product Name`)}
                  <input className={`ant-input`} placeholder='COGS' value={item?.cart_item?.supplier_stock_code ? item?.cart_item?.supplier_stock_code : item?.scraper?.stock_code} disabled />
                </span>
                {user?.additional_vendors?.length > 0 && <span>{t(`home.Vendor`)}
                  <input className={`ant-input`} placeholder='Vendor' value={item?.cart_item?.sub_vendor_name ? `${item?.cart_item?.vendor_name} - ${item?.cart_item?.sub_vendor_name}` : item?.cart_item?.vendor_name} disabled />
                </span>}

                {item?.cart_item?.is_discounted ? <span className='discounted-tag'>{t(`fields.order_products.discounted_product`)}</span> : null}

              
              <div className="same-row-container">

              {item?.cart_item?.vendor_product_id?.[item?.cart_item?.series] > 0  &&<div className='product-info-item product-series'>
               <span>{t(`fields.product.Product Series Barcode`)}
                  <input className={`ant-input`} placeholder='Vendor' value={item?.cart_item?.vendor_product_id?.[item?.cart_item?.series]} disabled />
                </span>
              </div>}
              <div className='product-info-item product-series'>
                <span className='product-info-label'>{t(`fields.product.Series`)}</span>
                <input className={`ant-input`} placeholder='Series' showSearch value={item?.cart_item?.series} disabled />
              </div>

              <div className='product-info-item product-quantity'>
                <span className='product-info-label'>{t(`fields.order_products.Quantity`)}</span>
                <input className='ant-input' placeholder='Quantity' value={item?.cart_item?.quantity} disabled />
              </div>
              <div className='product-info-item product-count'>
                <span className='product-info-label'>{t(`fields.order_products.Item Count`)}</span>
                <input className='ant-input' placeholder='Item Count' value={item?.cart_item?.item_count} disabled />
              </div>
              </div>

              <div className='product-info-item product-cogs'>
                <span className='product-info-label'>{t(`fields.order_products.COGS`)}</span>
                {<input 
                    className={`ant-input`} 
                    value={renderCalculatedVendorCogs({
                        payment_at: item?.payment_at, 
                        cogs: item?.cart_item?.cogs, 
                        rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                        rate_usd_buying: item?.rate_usd_buying, 
                        rate_usd_selling: item?.rate_usd_selling, 
                        })} 
                    disabled />}
              </div>
              <div className='product-info-item product-unit'>
                <span className='product-info-label'>{t(`fields.order_products.Unit Price (Including VAT)`)}</span>

                <input className='ant-input' placeholder='Item Count' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${unitPrice}`} disabled />
              </div>

              <div className='product-info-item product-sum'>
                <span className='product-info-label'>{t(`fields.order_products.Total Price (Including VAT)`)}</span>
                <input className='ant-input' placeholder='Item Count' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${totalPrice}`} disabled />

              </div>

              <div className='product-info-item product-sum'>
                <span className='product-info-label'>{t(`fields.order_products.Rate Usd Tr Buying`)}</span>
                {<input className='ant-input' placeholder='Rate Usd Tr' value={renderLoncaVendorCurrency(item)} disabled />}
              </div>

              {item?.coupon && item?.coupon?.discount_type === "Percentage" 
              ? <div className='product-info-item product-discount'>
                <span className='product-info-label'>{t(`fields.order_products.Discount Amount`)}</span>
                <input className='ant-input' placeholder='Item Count' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${discountAmount}`} disabled />
              </div> 
              : null}

              <div className={item?.cart_item?.supplier_link ? 'product-coupon' : 'product-coupon'}>
                {item?.coupon ?
                  <span>{t(`fields.order_products.Used Coupon`)}: {item?.coupon?.code}</span>
                  : null}
              </div>

            </div>
          </div>

        )
      })}
    </div>
  </div >

  )
}

export default OrderProducts;