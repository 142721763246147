import React from 'react';
import { useTranslation } from 'react-i18next';

const UpdateConfigEditor = ({ productInitialConfig, product, setProduct }) => {
  const { t } = useTranslation('translation');
  const configAssignableFields = ['series', 'status', 'price'];

  const handleConfigChange = (field) => {
    const isFixed = product?.update_config?.[field] === 'fixed';

    if (isFixed) {
      setProduct({
        ...product,
        update_config: { ...product?.update_config, [field]: '' }
      });
    } else if (productInitialConfig?.[field] === 'fixed') {
      setProduct({
        ...product,
        update_config: { ...product?.update_config, [field]: 'fixed' }
      });
    }
  };

  return (
    <div>
        <p style={{ textAlign: 'left' }}>{t("fields.product.Update Configs")}</p>
      {configAssignableFields.map((field) => (
        <div className="checkbox-container" key={field}>
          <label>
            <input
              type="checkbox"
              onChange={() => handleConfigChange(field)}
              checked={product?.update_config?.[field] === 'fixed'}
            />
            {t("fields.product.Field is not updated") + ": "  + t(`fields.product.${field}`)}
          </label>
        </div>
      ))}
    </div>
  );
};

export default UpdateConfigEditor;
